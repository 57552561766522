export default () => ({
  idContact: 0,
  first_name: "",
  last_name: "",
  phone: "",
  email: "",
  date_modified: "",
  date_created: "",
  city: "",
  state: "",
  mailchimp: {
    id: 0,
    status: "",
  },
  country: "",
  url: "",
});
